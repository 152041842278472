import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import '../utils/prototypes.js';
import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`;

const Layout = ({ children, title=null, lang, slug }) => {
  const titleB = title ? ` - ${title}` : '';

  const langCode = {
    'en': 'en-us', 
    'de': 'de-de', 
    'ja': 'ja-jp', 
    'fr': 'fr-fr', 
    'es-MX': 'es-mx', 
    'pt-BR': 'pt-br', 
    'ko': 'ko-kr', 
    'zh-CN': 'zh-cn', 
    'it': 'it-it', 
    'es': 'es-es'
  }

  const isoCode = langCode[lang] ? langCode[lang] : 'en';

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={`${siteMetadata.title}${titleB}`}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta http-equiv="Cache-control" content="no-cache" />

            <link rel="canonical" href={`https://trailhead.salesforce.com/credentials/${slug}`} />

            <meta http-equiv="content-language" content={isoCode} />
            <link href={`https://trailhead.salesforce.com/credentials/${slug}`} hreflang="x-default" rel="alternate"></link>

            {
              Object.keys(langCode).map((key, index) => {
                return (<link key={`meta-link_${index}`} href={`https://trailhead.salesforce.com/${key}/credentials/${slug}`} hreflang={langCode[key]} rel="alternate"></link>)
              })
            }

            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            <meta property="og:title" content={siteMetadata.title} />
            <meta property="og:image:url" content="https://trailhead-web.s3.amazonaws.com/uploads/users/5396019/photos/thumb_030804d3576dab0cdc2a558055816208e421312a9d1495117d57928ef380d7f2.png" />
            <meta property="og:description" content={siteMetadata.description} />
            
            <meta name="twitter:title" content={siteMetadata.title} />
            <meta name="twitter:image" content="https://trailhead-web.s3.amazonaws.com/uploads/users/5396019/photos/thumb_030804d3576dab0cdc2a558055816208e421312a9d1495117d57928ef380d7f2.png" />
            <meta name="twitter:description" content={siteMetadata.description} />
            
            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/Nexa/Nexa.css"/>
            
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />

          </Helmet>
          <div id="cert-site" className="cert-site dsc-content">
            <div dangerouslySetInnerHTML={{ __html: `<!-- Migrated App -->` }} />
            {children}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
