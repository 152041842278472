
// ADDING CUSTOM METHODS TO STANDARD ARRAY OBJECT
(function() {
  this.sortAsc = function(key=null) {
    if(typeof(this[0]) == 'number' || typeof(this[0]) == 'string') {
      return this.sort((a,b) => a > b ? 1 : -1);
    } else if(typeof(this[0]) == 'object') {
      return key != null && Object.hasOwnProperty.call(this[0], key)
            ? this.sort((a,b) => a[key] > b[key] ? 1 : -1)
            : this;
    } else {
      return this;
    }
  };

  this.sortDesc = function(key=null) {
    if(typeof(this[0]) == 'number' || typeof(this[0]) == 'string') {
      return this.sort((a,b) => a < b ? 1 : -1);
    } else if(typeof(this[0]) == 'object') {
      return key != null && Object.hasOwnProperty.call(this[0], key)
            ? this.sort((a,b) => a[key] < b[key] ? 1 : -1)
            : this;
    } else {
      return this;
    }
  }
}).call(Array.prototype);

// ADDING CUSTOM METHODS TO STANDARD STRING OBJECT
(function() {
  this.toSlug = function() {
    return this.replace(/_/g, '');
  };
}).call(String.prototype);
